import { DirectiveOptions } from 'vue';

const directive: DirectiveOptions = {
  bind (el: any, binding: any, vnode: any) {
    // для обхода eslint: Assignment to property of function parameter 'el'
    const element = Object.assign(el, {});

    element.clickOutsideEvent = function cb (event: Event) {
      if (!(element === event.target || element.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', element.clickOutsideEvent);
  },
  unbind (el: any) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
};

export default directive;
