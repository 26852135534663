
















































































import Vue, { PropType } from 'vue';
import { mapGetters, mapState } from 'vuex';
import { getDateText, getFormattedDate } from '@devhacker/shared/utils/date';
import ArticleCardMiddle from '@/components/lh-ui/ArticleCard/ArticleCardMiddle/ArticleCardMiddle.vue';
import ArticleCardLarge from '@/components/lh-ui/ArticleCard/ArticleCardLarge/ArticleCardLarge.vue';
import IntersectionObserver from '@/components/lh-ui/IntersectionObserver/IntersectionObserver.vue';
import TheSlider from '~/components/slider';
import ArticleCardSquare from '~/components/ArticleCardSquare.vue';
import FavoriteMethodsMixin from '~/mixins/FavoriteMethodsMixin';
import UpdatingViewsCountMixin from '~/mixins/UpdatingViewsCountMixin';
import { getEditLinkHref, getFullUrl, getRelativeLink, isHomeLink } from '~/utils';
import { GET_AB_TITLE_BY_ARTICLE } from '~/constants';
import { FoldersState } from '~/store/folders';
import { eventAction } from '~/constants/analytics';
import { isArticlePage, isHomePage, isAnyRecipePage, isHealthPath, isHealthMain } from '~/router';

import getSlugFromUrl from '~/utils/getSlugFromUrl';
import { ADS_LABELS, ADS_EXCEPTIONS } from '~/constants/ads-labels';

export default Vue.extend({
  name: 'TaxonomyPostsContainer',

  eventAction,

  components: {
    ArticleCardMiddle,
    ArticleCardLarge,
    TheSlider,
    ArticleCardSquare,
    IntersectionObserver,
  },

  mixins: [FavoriteMethodsMixin, UpdatingViewsCountMixin],

  props: {
    articles: {
      type: Array as PropType<IArticle[]>,
      required: true,
    },
    inSidebar: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    title: {
      type: String as PropType<string | undefined>,
      required: false,
      default: undefined,
    },
  },

  data () {
    return {
      articlesWithLoadedPixels: [] as number[],
    };
  },

  computed: {
    ...mapState(['folders', 'user']),
    ...mapGetters({
      getArticleFavoriteId: 'getArticleFavoriteId',
      getCommentsCount: 'getCommentsCount',
      getViewsCount: 'getViewsCount',
      getTestTitle: GET_AB_TITLE_BY_ARTICLE,
    }),

    isHealth (): boolean {
      return isHealthPath(this.$route.path);
    },

    isHealthMain (): boolean {
      return isHealthMain(this.$route.name);
    },

    isAuthenticatedInWordpress (): boolean {
      return (this.$store.state as IRootState).isAuthenticatedInWordpress;
    },
  },

  watch: {
    '$route.fullPath' () {
      this.articlesWithLoadedPixels = [];
    },
  },

  methods: {
    // TO DO: похожий метод в
    // src/mixins/ArticleCardContainerMixin.ts,
    // src/containers/ZenRotationContainer.vue
    // можно объединить, при этом ВАЖНО сохранить значения и набор свойств, тк они отличаются
    // в миксинах и в виджете, например title
    // В миксине выбирается тестовый заголовок и сохраняется в сторе если он есть.
    // А в виджете только выбирается сохраненный тестовый заголовок из стора если он
    // есть или основной заголовок. Плюс в миксине кроме этого метода есть еще другие,
    // которые не нужны в виджете
    getArticleProps (article: IWidgetArticle): { [propName: string]: any } {
      const {
        id,
        categories,
        author,
        url: articleUrl,
        isAdvertising,
        isPromo,
        commentsEnabled,
        img,
        defaultImg,
        date,
        img_alt,
        title,
        teaserUrl,
        advertLabel,
      } = article;

      const abTestTitle = this.getTestTitle(id);
      const internalUrl = teaserUrl ? false : isHomeLink(articleUrl);
      const url = teaserUrl || (internalUrl ? getRelativeLink(articleUrl) : articleUrl);

      return {
        categories,
        isPromo,
        isAdvertising: isAdvertising && !ADS_EXCEPTIONS.includes(advertLabel),
        isTeaser: !!teaserUrl,
        commentsEnabled,
        internalUrl,
        url,
        advertLabel,
        partnerText: ADS_LABELS[advertLabel],
        imgAlt: img_alt,
        title: abTestTitle || title,
        images: img,
        defaultImages: defaultImg,
        authorText: author?.name || '',
        authorUrl: author?.profile || '',
        inFavorites: (this.getArticleFavoriteId(id) > 0),
        dateText: this.isHealthMain ? getDateText(date) : getFormattedDate(date),
        commentsCount: this.getCommentsCount(id),
        isAuthenticatedInWordpress: this.isAuthenticatedInWordpress,
        editLinkHref: getEditLinkHref(id),
        viewsCount: this.getViewsCount(id),
        user: this.$store.state.user as IRootState['user'],
        folders: this.$store.state.folders as FoldersState,
        favorite: this.$store.getters.getArticleFavorite(id) as IFavoriteItem,
        favoriteMenuOffsets: {
          top: 48, // учитывать fixed шапку
        } as TOffsets,
      };
    },

    isTrackTheAppearance (article: IArticle): boolean {
      return Boolean(article?.testPixel);
    },

    getObserverProps (article: IArticle): { is: string } {
      const componentName = this.isTrackTheAppearance(article) ? 'IntersectionObserver' : 'div';
      return {
        is: componentName,
      };
    },

    onAppear (article: IArticle): void {
      const testPixel = article?.testPixel;
      const isNotLoadedPixel = !this.articlesWithLoadedPixels.includes(article.id);
      if (testPixel?.length && isNotLoadedPixel) {
        testPixel.forEach(url => this.$axios(url, { withCredentials: true }));
        this.articlesWithLoadedPixels.push(article.id);
      }
    },

    getSliderList (articles: IArticle[]): IArticle[] {
      return articles.map((item) => {
        const testTitle = this.getTestTitle(item.id);

        return {
          ...item,
          title: testTitle || item.title,
          images: item.image && item.image.sizes,
          defaultImg: item.defaultImg,
        };
      });
    },

    onClickArticleCard (article: IArticle, type: any): void {
      const { name } = this.$route;
      const category = isHomePage(name) ? 'Главная' : isArticlePage(name) ? 'Внутренние' : '';
      const { teaserUrl } = article;
      const url = teaserUrl || getFullUrl(article.url);
      if (category) {
        this.$sendAnalyticsEvent({
          event: `выбор материала_${category}`,
          slug_location: getSlugFromUrl(this.$route.fullPath),
          slug_referrer: url,
          element: type,
          item: '',
          action: '',
          value: 1,
          currency: 'piece',
        });

        this.$sendYandexMetrika({
          level1: `Выбор материала_таксономия_${category}`,
          level4: url,
          level6: 'Тизер',
        });
      }

      if (isHomePage(name)) {
        if (teaserUrl) {
          this.$sendAnalyticsEvent({
            event: `выбор материала_${category}`,
            slug_location: getSlugFromUrl(this.$route.fullPath),
            slug_referrer: url,
            element: 'Тизер',
            item: '',
            action: '',
            value: 1,
            currency: 'piece',
          });

          this.$sendYandexMetrika({
            level1: `Выбор материала_таксономия_${category}`,
            level4: url,
            level6: 'Тизер',
          });
        }
      }

      this.sendAnalyticsArticle(url);

      if (isAnyRecipePage(this.$route.name)) {
        this.sendAnalyticsRecipe(article.url);
      }
    },

    sendAnalyticsArticle (url: string) {
      if (isHomePage(this.$route.name)) {
        return;
      }

      const articleIndex = this.articles.findIndex(elem => getFullUrl(elem?.url) === url || elem?.teaserUrl === url);
      const event = 'Клик_Лучшие предложения';

      this.$sendAnalyticsEvent({
        event,
        slug_location: getSlugFromUrl(this.$route.fullPath),
        slug_referrer: this.articles[articleIndex].url,
        element: '',
        item: '',
        action: '',
        value: 1,
        currency: 'piece',
      });

      this.$sendAnalyticsSnowPlow({
        event_name: event,
        par3: this.articles[articleIndex]?.url,
        par4: String(articleIndex + 1),
      });

      this.$sendYandexMetrika({
        level1: 'Выбор материала_Виджет большая карточка',
        level4: getFullUrl(this.articles[articleIndex]?.url),
      });
    },

    sendAnalytics (link: string) {
      if (isAnyRecipePage(this.$route.name)) {
        this.sendAnalyticsRecipe(link);
      } else {
        this.sendAnalyticsArticle(link);
      }
    },

    sendAnalyticsRecipe (link: string) {
      this.$sendAnalyticsEvent({
        event: 'Выбор материала',
        slug_location: getSlugFromUrl(this.$route.fullPath),
        slug_referrer: getSlugFromUrl(link),
        // @ts-ignore
        element: 'Таксономия',
        item: '',
        action: '',
        value: 1,
        currency: 'piece',
      });
    },

    onClickAuthor (): void {
      if (isHomePage(this.$route.name)) {}
    },

    onClickCategory (link: string, titleCategory: string, _title: string) {
      this.$sendYandexMetrika({
        level1: 'Выбор рубрики',
        level4: getFullUrl(link),
        level5: titleCategory,
        level6: 'unknown',
      });
    },

    getLink (article: IArticle) {
      const {
        url: articleUrl,
        teaserUrl,
      } = article;

      const internalUrl = teaserUrl ? false : isHomeLink(articleUrl);
      const url = teaserUrl || (internalUrl ? getRelativeLink(articleUrl) : articleUrl);

      return url;
    },

  },
});
