










import Vue from 'vue';

import CommentsWidget from '~/components/commentWidget/CommentsWidget.vue';

import { Comment } from '@/types';
import getSlugFromUrl from '~/utils/getSlugFromUrl';
import { getFullUrl } from '~/utils';

export default Vue.extend({
  name: 'CommentsWidgetContainer',

  components: {
    CommentsWidget,
  },

  data: () => ({
    commentsLast: [] as Array<Comment>,
  }),

  async fetch () {
    const { data: lastCommentData } = await this.$commentsApi.fetchLastComments();
    if (lastCommentData.success) {
      this.commentsLast = lastCommentData.data;
    }
  },

  computed: {
    abTitles (): { [propName: string]: string } {
      return this.$store.state.abTestData.titles;
    },
  },

  watch: {
    '$route.fullPath': '$fetch',
  },

  methods: {
    sendAnalyticsEvent (element: string, postSlug: string) {
      this.$sendAnalyticsEvent({
        event: 'Комментарии_переход к комментариям',
        slug_location: getSlugFromUrl(this.$route.fullPath),
        slug_referrer: postSlug,
        element: `Виджет "${element} комментарии"`,
        item: '',
        action: '',
        value: 1,
        currency: 'piece',
      });

      this.$sendYandexMetrika({
        level1: 'Комментарии_переход к комментариям',
        level4: getFullUrl(postSlug),
        level6: `Виджет "${element} комментарии"`,
      });
    },
  },
});
