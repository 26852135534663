














import Vue, { PropType } from 'vue';
import { Swiper } from 'swiper';
import { TTile } from './types';
import TileItem from '@/components/Tile/TileItem.vue';

export default Vue.extend({
  name: 'Tilecontainer',

  components: {
    TileItem,
  },

  props: {
    tiles: {
      type: Array as PropType<Array<TTile>>,
      required: true,
    },
    enableSwiper: {
      type: Boolean,
      required: true,
    },
  },

  data () {
    return {
      swiper: {} as Swiper,
      isFirstSendEventSwiperScroll: true,
    };
  },

  computed: {
    computedClass () {
      return {
        'swiper--disabled': !this.enableSwiper,
      };
    },
  },

  async mounted () {
    const { Swiper } = await import('swiper');
    const TileSwiper = this.$refs.swiperTile as HTMLElement;

    this.swiper = new Swiper(TileSwiper, {
      spaceBetween: 12,
      observer: true,
      observeParents: true,
      centeredSlidesBounds: true,
      slidesPerView: 'auto',
      slidesOffsetAfter: 16,
      slidesOffsetBefore: 16,
    });

    this.swiper.on('slideChange', () => {
      if (this.isFirstSendEventSwiperScroll) {
        this.$emit('scroll-swiper');
        this.isFirstSendEventSwiperScroll = false;
      }
    });

    this.enableSwiper ? this.swiper.enable() : this.swiper.disable();
  },
});
