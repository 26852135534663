













import Vue from 'vue';
import Cookies from 'js-cookie';
import { HIDE_SAFARI_PUSH_DIALOG } from '~/constants';
import { SAFARI_COOKIE_NAME } from '~/constants/config';

import { getPushNotificationsManager } from '~/plugins/push-notifications.client';

export default Vue.extend({
  name: 'SafariPushWindowContainer',
  methods: {
    handleCloseClick (): void {
      this.$store.commit(HIDE_SAFARI_PUSH_DIALOG);
      Cookies.set(SAFARI_COOKIE_NAME, '1', { expires: 7, path: '/' });
    },
    handleYesOption (): void {
      const pm = getPushNotificationsManager(this.$store, this.$pushApi);
      pm.requestSafariPermission();
    },
  },
});
