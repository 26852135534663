

















































import Vue, { PropType } from 'vue';
import { mapGetters } from 'vuex';

// @ts-ignore
import IntersectionObserver from '@/components/lh-ui/IntersectionObserver/IntersectionObserver.vue';

import { GET_AB_TITLE_BY_ARTICLE } from '~/constants';

import { shuffleArray } from '~/utils/arrays';
import ArticleCardSquare from '~/components/ArticleCardSquare.vue';
import { getRelativeLink, isHomeLink, getFullUrl } from '~/utils';
import { isHomeHealth, isHomePage } from '~/router';

export default Vue.extend({
  name: 'PostCollectionContainer',
  components: {
    IntersectionObserver,
    ArticleCardSquare,
  },
  props: {
    articles: {
      type: Array as PropType<IArticle[]>,
      required: true,
    },
    title: {
      type: String as PropType<string>,
      default: '',
    },
    inSidebar: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    rotationTime: {
      type: Number as PropType<number>,
      default: 30,
    },
  },
  data: () => ({
    tiles: {
      showItems: [] as Array<IArticle>, // показываются в данный момент
      default: {
        count: 4, // количество показываемых тайлов (статей)
      },
      sidebar: {
        count: 1,
      },
    },
    intervalId: null as any,
    articlesWithLoadedPixels: [] as number[],
  }),

  fetch () {
    this.tilesRotationInit();
  },

  computed: {
    ...mapGetters({
      getTestTitle: GET_AB_TITLE_BY_ARTICLE,
    }),

    shuffleArticles (): Array<IArticle> {
      return shuffleArray(this.articles);
    },

    location (): string {
      switch (true) {
        case isHomePage(this.$route.name):
          return 'Главная';
        case this.inSidebar:
          return 'Сайдбар';
        default:
          return '';
      }
    },

    tilesType (): any {
      return this.inSidebar ? this.tiles.sidebar : this.tiles.default;
    },

    tilesCount (): number {
      return this.tilesType.count;
    },
  },

  destroyed () {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },

  methods: {
    getLink (article: IArticle) {
      const {
        url: articleUrl,
        teaserUrl,
      } = article;

      const internalUrl = teaserUrl ? false : isHomeLink(articleUrl);
      const url = teaserUrl || (internalUrl ? getRelativeLink(articleUrl) : articleUrl);

      return url;
    },
    onAppear (article: IArticle): void {
      const testPixel = article?.testPixel;
      const isNotLoadedPixel = !this.articlesWithLoadedPixels.includes(
        article.id,
      );
      if (testPixel?.length && isNotLoadedPixel) {
        testPixel.forEach(url => this.$axios(url, { withCredentials: true }));
        this.articlesWithLoadedPixels.push(article.id);
      }
    },
    tilesRotationInit (): void {
      if (this.shuffleArticles.length > this.tilesCount) {
        this.tiles.showItems = this.shuffleArticles.slice(0, this.tilesCount);

        this.tilesRotation();
      } else {
        this.tiles.showItems = this.shuffleArticles;
      }
    },
    tilesRotation (): void {
      const {
        tiles: { showItems },
        shuffleArticles,
        tilesCount,
      } = this;

      const lastShowArticleIndex = shuffleArticles.findIndex((article) => {
        const lastShowArticle = showItems[showItems.length - 1];
        return article.id === lastShowArticle.id;
      });

      const newItems = shuffleArticles.slice(
        lastShowArticleIndex + 1,
        lastShowArticleIndex + tilesCount + 1,
      );

      if (newItems.length < tilesCount) {
        newItems.push(
          ...shuffleArticles.slice(0, tilesCount - newItems.length),
        );
      }

      this.tiles.showItems = newItems;
    },
    isTrackTheAppearance (article: IArticle): boolean {
      return Boolean(article?.testPixel?.length);
    },
    getObserverProps (article: IArticle): { is: string } {
      const componentName = this.isTrackTheAppearance(article)
        ? 'IntersectionObserver'
        : 'div';
      return {
        is: componentName,
      };
    },
    sendAnalytics (category: string, link: string, order: number) {
      const slugCurrentPage = getRelativeLink(window.location.pathname);
      const slugTransitionPage = getRelativeLink(link);

      const isMain = isHomePage(this.$route.name) ? 'Главная' : isHomeHealth(this.$route.name) ? 'Главная здоровье' : '';

      this.$sendAnalyticsEvent({
        event: `Выбор материала_коллекция постов ${isMain}`,
        slug_location: slugCurrentPage,
        slug_referrer: slugTransitionPage,
        element: isMain || category,
        item: this.inSidebar ? 'Сайдбар' : 'Виджет',
        action: '',
        value: 1,
        currency: 'piece',
      });

      !this.inSidebar && !isMain && this.$sendAnalyticsSnowPlow({
        event_name: 'Клик_Коллекция постов',
        par3: getFullUrl(link),
        par4: String(order),
      });

      this.$sendYandexMetrika({
        level1: 'Выбор материала_коллекция постов',
        level4: getFullUrl(link),
        level5: order,
        level6: this.title,
        level8: isMain || category,
      });
    },
  },
});
