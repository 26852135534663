


















































import IntersectionObserver from '@/components/lh-ui/IntersectionObserver/IntersectionObserver.vue';
import ArticleCardContainerMixin from '~/mixins/ArticleCardContainerMixin';
import FavoriteMethodsMixin from '~/mixins/FavoriteMethodsMixin';
import UpdatingViewsCountMixin from '~/mixins/UpdatingViewsCountMixin';
import getSlugFromUrl from '~/utils/getSlugFromUrl';
import ArticleCardMiddle from '@/components/lh-ui/ArticleCard/ArticleCardMiddle/ArticleCardMiddle.vue';
import ArticleCardLarge from '@/components/lh-ui/ArticleCard/ArticleCardLarge/ArticleCardLarge.vue';
import { getFullUrl } from '~/utils';

export default ArticleCardContainerMixin.extend({
  name: 'TheTvContainer',

  data () {
    return {
      articlesWithLoadedPixels: [] as number[],
    };
  },

  mixins: [FavoriteMethodsMixin, UpdatingViewsCountMixin],

  components: {
    ArticleCardMiddle,
    ArticleCardLarge,
    IntersectionObserver,
  },

  computed: {
    articles (): Array<IArticle> {
      return this.$store.getters.currentArticles.slice(0, 5);
    },
    firstArticle (): IArticle {
      return this.articles[0];
    },
    otherArticles (): Array<IArticle> {
      return this.articles.slice(1, 5);
    },
  },
  methods: {
    sendEvent (slug: string) {
      this.$sendAnalyticsEvent({
        event: 'Комментарии_переход к комментариям',
        slug_location: getSlugFromUrl(this.$route.fullPath),
        slug_referrer: slug,
        element: 'главная',
        item: '',
        action: '',
        value: 1,
        currency: 'piece',
      });

      this.$sendYandexMetrika({
        level1: 'Комментарии_переход к комментариям',
        level4: getFullUrl(slug),
        level6: 'главная',
      });
    },
    onAppear (article: IArticle): void {
      this.onAbTitleEvent(article, 'view');

      const testPixel = article?.testPixel;
      const isNotLoadedPixel = !this.articlesWithLoadedPixels.includes(
        article.id,
      );
      if (testPixel?.length && isNotLoadedPixel) {
        testPixel.forEach(url => this.$axios(url, { withCredentials: true }));
        this.articlesWithLoadedPixels.push(article.id);
      }
    },
    isTrackTheAppearance (article: IArticle): boolean {
      const hasAbTitlesData = this.hasAbTitlesData(article?.abTitlesData);
      const hasTestPixel = Boolean(article?.testPixel);
      return hasAbTitlesData || hasTestPixel;
    },
    onClickArticleCardLarge (): void {
      this.onAbTitleEvent(this.firstArticle, 'click');

      const { teaserUrl } = this.firstArticle;
      const url = teaserUrl || this.firstArticle.url;
      this.$sendAnalyticsEvent({
        event: 'выбор материала_Главная',
        slug_location: getSlugFromUrl(this.$route.fullPath),
        slug_referrer: url,
        element: 'большая акцентная карточка',
        item: 'не виджет',
        action: '',
        value: 1,
        currency: 'piece',
      });
      if (teaserUrl) {
        this.$sendAnalyticsEvent({
          event: 'выбор материала_Главная',
          slug_location: getSlugFromUrl(this.$route.fullPath),
          slug_referrer: url,
          element: 'Тизер',
          item: 'не виджет',
          action: '',
          value: 1,
          currency: 'piece',
        });
      }
      this.$sendYandexMetrika({
        level1: 'Выбор материала_Виджет большая карточка',
        level4: getFullUrl(url),
      });
    },
    onClickArticleCardMiddle (article: IArticle): void {
      this.onAbTitleEvent(article, 'click');

      const { teaserUrl } = article;
      const slug = teaserUrl || getSlugFromUrl(article.url);

      this.$sendAnalyticsEvent({
        event: 'выбор материала_Главная',
        slug_location: getSlugFromUrl(this.$route.fullPath),
        slug_referrer: slug,
        element: 'малая акцентная карточка',
        item: 'не виджет',
        action: '',
        value: 1,
        currency: 'piece',
      });

      if (teaserUrl) {
        this.$sendAnalyticsEvent({
          event: 'выбор материала_Главная',
          slug_location: getSlugFromUrl(this.$route.fullPath),
          slug_referrer: slug,
          element: 'Тизер',
          item: 'не виджет',
          action: '',
          value: 1,
          currency: 'piece',
        });
      }
    },
    onClickAuthor (): void {},
    onClickCategory (link: string, titleCategory: string, _title: string): void {
      this.$sendAnalyticsEvent({
        event: 'Выбор рубрики',
        slug_location: getSlugFromUrl(this.$route.fullPath),
        slug_referrer: getSlugFromUrl(link),
        element: 'Главная',
        item: titleCategory,
        action: '',
        value: 1,
        currency: 'piece',
      });

      this.$sendYandexMetrika({
        level1: 'Выбор рубрики',
        level4: getFullUrl(link),
        level5: titleCategory,
        level6: 'unknown',
      });
    },
  },
});
